import 'chosen-js/chosen.jquery';
import {MuiForm} from "../../../shared/components/mui-form/mui-form";
import {AjaxDataLoad} from "../../../shared/components/ajax-data-load/ajax-data-load";
import {url} from "../../../../shared/utils";

export const RegistrationModal = (function () {

    const EMPTY_FORM_VALUE = '';

    const BUSINESS_TYPE = {
        buy: 100,
        sell: 200,
        buyAndSell: 300
    };

    const REASON_TYPE = {
        forOwnUse: 1000,
        toResell: 2000,
        whatIUsed: 3000,
        imReseller: 4000
    };

    const TRADE_TYPE = {
        oneItem: 10000,
        multipleItems: 20000,
        toEndUsers: 30000,
        toResellers: 40000
    }

    const exports = {
        $slider: null,
        formState: {
            manufacturers_select: false,
            categories_select: false,
            email: false
        }
    };

    function updateLayout(data)
    {
        for (let id in data) {
            if (!data.hasOwnProperty(id)) {
                continue;
            }

            if (id === 'hidden_panel') {
                // plugins initialized incorrectly in case using 'display: none'
                if (data[id]) {
                    document.getElementById(id).className = document.getElementById(id).className.replace(/\btl-hidden-height\b/, '');
                } else {
                    if (document.getElementById(id).className.indexOf('tl-hidden-height') === -1) {
                        document.getElementById(id).className += ' tl-hidden-height';
                    }
                }
            } else {
                document.getElementById(id).style.display = data[id] ? 'block' : 'none';
            }
        }
    }

    function isValidForm()
    {
        for (let key in exports.formState) {
            if (exports.formState.hasOwnProperty(key)) {
                if (!exports.formState[key]) {
                    return false;
                }
            }
        }

        return true;
    }

    function renderCategories(cats)
    { 
        const result = [];
        let catOnes = [];
       
        cats.data.forEach(function(item) {
            if (!catOnes[item.cat1id]) {
                catOnes[item.cat1id] = {
                    id:             item.cat1id,
                    title:          item.cat1
                };
            }
        });

        catOnes.sort((a, b) => {
            return a.title.localeCompare(b.title);
        });

        catOnes.forEach(item => { 
            const subCategories = [];
            cats.data.forEach(function(subItem) {
                if(subItem.cat1id === item.id){
                    subCategories.push('<option value="' + subItem.cat2id + '">' + subItem.cat2 + '</option>');
                }     
            });
            result.push('<optgroup label="' + item.title + '">' + subCategories.join('') + '</optgroup>');
        }); 

        document.getElementById('categories_select').innerHTML = result.join('');

        $('#categories_select').chosen({
            width: '100%'
        });
    }

    function loadCategories() {
        return new Promise((resolve, reject) => {
            framework.post(url('api/categories/cat-two/search'), {
                _token: $('input[name="_token"]:first').val()
            }, function(r) {  
                resolve(r)
            }, function(e, data) {
                reject(data)
            });
        })
    }

    function renderManufacturers(manus)
    {
        const result = [];
        
        manus.data.forEach(item => {
            result.push('<option value="' + item.id + '">' + item.title + '</option>');
        });

        document.getElementById('manufacturers_select').innerHTML = result.join('');

        $('#manufacturers_select').chosen({
            width: '100%'
        });
    }

    function loadManufacturers() {
        return new Promise((resolve, reject) => {
            framework.post(url('api/manufactures/search'), {
                _token: $('input[name="_token"]:first').val(),
                is_major:    "1"
            }, function(r) {  
                resolve(r)
            }, function(e, data) {
                reject(data)
            });
        })
    }

    exports.init = () => {
        MuiForm.init();

        loadCategories().then((data) => {
            setTimeout(() => {
                renderCategories(data)
            }, 750);    
        });

        loadManufacturers().then((data) => {
            setTimeout(() => {
                renderManufacturers(data)
            }, 500);    
        });
         
        $('input[name="business_type"]').change(event => {
            const value = parseInt(event.target.value);

            updateLayout({
                buy_panel: value === BUSINESS_TYPE.buy,
                sell_panel: value === BUSINESS_TYPE.sell,
                equipment_panel: false,
                reseller_panel: false,
                hidden_panel: value === BUSINESS_TYPE.buyAndSell,
            });

            $('input[name="reason_type"]').prop('checked', false);
        });

        $('input[name="reason_type"]').change(event => {
            const value = parseInt(event.target.value);

            updateLayout({
                equipment_panel: value === REASON_TYPE.forOwnUse || value === REASON_TYPE.whatIUsed,
                reseller_panel: value === REASON_TYPE.toResell || value === REASON_TYPE.imReseller,
                hidden_panel: false,
            })

            $('input[name="trade_type"]').prop('checked', false);
        });

        $('input[name="trade_type"]').change(() => {
            updateLayout({
                hidden_panel: true,
            });
        });

        $('select[name="manufacturers_select"], select[name="categories_select"]').change(event => {
            exports.formState[event.target.name] = event.target.value !== EMPTY_FORM_VALUE;
            checkForm();
        });

        $('input[name=email]').on('keyup', (event) => {
            exports.formState.email = $(event.target).data('isValid');
            checkForm();
        });

        $(".chosen-link").chosen({
            disable_search: true,
            inherit_select_classes: true,
            width: 'auto'
        });

        $('#form_submit').click(function () {
            if (!isValidForm()) {
                MuiForm.showError('Please fill all required fields.');
                return;
            }

            MuiForm.hideError();
            MuiForm.lockSubmit();
            MuiForm.showLoading('Processing...');

            let data = {
                buyingOrSelling:    parseInt($('input[name=business_type]:checked').val()),
                whyBuyOrSell:       parseInt($('input[name=reason_type]:checked').val()),
                moreBuyOrSell:      parseInt($('input[name=trade_type]:checked').val())
            };

            data.regIntent = getRegIntent(data.buyingOrSelling);
            postRegisterData(data);
        });
    }

    function checkForm() {
        if (isValidForm()) {
            MuiForm.unlockSubmit();
        } else {
            MuiForm.lockSubmit();
        }
    }

    function getRegIntent(buyingOrSelling) {
        let regIntent = [];
        regIntent.push($('input[name=business_type]:checked').attr('data-intent'));

        if (buyingOrSelling !== BUSINESS_TYPE.buyAndSell) {
            regIntent.push($('input[name=reason_type]:checked').attr('data-intent'));
            regIntent.push($('input[name=trade_type]:checked').attr('data-intent'));
        }

        return regIntent.join('-');
    }

    function postRegisterData(data) {
        const usingEmail = $('input[name=email]').val();
        const emlSugg = $('#emlSugg').val();
        const emlVals = typeof emlSugg != 'undefined' ? 0 : 1;
        let sellsToEndUsers = 0;

        if (data.buyingOrSelling === BUSINESS_TYPE.sell && data.moreBuyOrSell === TRADE_TYPE.toEndUsers) {
            sellsToEndUsers = 1;
        }

        const requestData = AjaxDataLoad.extendDataLoad({
            login: usingEmail,
            top_daily_email: $('input[name=top_daily_email]').is(':checked') ? 1 : 0,
            top_weekly_email: $('input[name=top_weekly_email]').is(':checked') ? 1 : 0,
            personalized_digest: $('input[name=personalized_digest]').is(':checked') ? 1 : 0,
            reg_intent_string: data.regIntent,
            buy_or_sell: data.buyingOrSelling,
            buy_or_sell_why: data.whyBuyOrSell,
            sells_to_end_users: sellsToEndUsers,
            emlVals: emlVals,
            manufacturers: $('select[name=manufacturers_select]').val(),
            categories: $('select[name=categories_select]').val()
        });

        /*
        digest_all: $('input[name=send_all]').is(':checked') ? 1 : 0,
        digest_all_frequency: $('select[name=all_frequency]').val(),
        digest_personalized: $('input[name=send_personalized]').is(':checked') ? 1 : 0,
        digest_personalized_frequency: $('select[name=personalized_frequency]').val(),
        */


        framework.post(url('modal/register/register'), requestData, function(r) {
            onPostRegisterSuccess(r, usingEmail);
        }, function(e, data) {
            onPostRegisterError(e, data, usingEmail);
        });
    }

    function onPostRegisterSuccess(r, usingEmail) {
        Analytics.trackInGA('1a. Registration / Setup / Verification',{
            'event_cat_id': 27,
            'event_cat_name':'1a. Registration / Setup / Verification',
            'tl_event_id':129,
            'tl_event_name':'1. Account Created'
        });
        tradeloop.modal('register/enter_password',null,{
            email: usingEmail
        });
    }

    function onPostRegisterError(e, data, usingEmail) {
        MuiForm.hideLoading();
        MuiForm.unlockSubmit();

        if (data.errorCode == 910) {
            MuiForm.showError(data.error);
        } else if (data.errorCode == 920) {
            MuiForm.showError(data.error);
            var emHtml = '<em class="invalid">Did you mean ' + data.suggestedEmail + '</em><input type="hidden" id="emlSugg" name="emlSugg" value="yes">';
            $('#emailAddressText').append(emHtml);
        } else {
            tradeloop.modal('login', function () {
                $('#email').val(usingEmail);
                tradeloop.show_modal_error(data.error);
            },{},'signup-modal/err=email-exists');
        }
    }

    return exports;
})();
